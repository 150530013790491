import { FC } from 'react';
// components
import PageLayout from 'src/components/PageLayout';
import Intro from 'src/components/Intro';
// sections
import Content from './sections/Content';
import { PageProps } from 'gatsby';

const Terms: FC<PageProps> = ({ location }) => {
  return (
    <PageLayout
      metaData={{
        title: 'Terms of Use - Seedium',
        description:
          'Learn more about the conditions of website use,  intellectual property rights, as well as Seedium limitation of liability.',
        href: location.href,
      }}
    >
      <Intro
        title='Terms of use'
        description='This page contains conditions of Seedium website use, defines intellectual property rights, external links policy and limitation of liability.'
        icon='termsDrawing'
      />
      <Content />
    </PageLayout>
  );
};

export default Terms;
