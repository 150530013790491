import { FC, memo } from 'react';

import type { ListProps } from './List.interface';
import * as style from './List.module.scss';

const List: FC<ListProps> = memo(({ Element = 'ul', list }) => {
  return (
    <Element className={style.list}>
      {list.map((item, index) => (
        <li key={index} className={style.item}>
          {item}
        </li>
      ))}
    </Element>
  );
});

export default List;
